.radix-themes {
  --space-1: calc(4px * var(--scaling));
  --space-2: calc(8px * var(--scaling));
  --space-3: calc(12px * var(--scaling));
  --space-4: calc(16px * var(--scaling));
  --space-5: calc(20px * var(--scaling));
  --space-6: calc(24px * var(--scaling));
  --space-7: calc(28px * var(--scaling));
  --space-8: calc(32px * var(--scaling));
  --space-9: calc(36px * var(--scaling));
  --space-10: calc(40px * var(--scaling));
  --space-11: calc(44px * var(--scaling));
  --space-12: calc(48px * var(--scaling));
  --space-13: calc(52px * var(--scaling));
  --space-14: calc(56px * var(--scaling));
  --space-15: calc(60px * var(--scaling));
  --space-16: calc(64px * var(--scaling));
  --space-17: calc(68px * var(--scaling));
  --space-18: calc(72px * var(--scaling));
  --space-19: calc(76px * var(--scaling));
  --space-20: calc(80px * var(--scaling));
  --space-21: calc(84px * var(--scaling));
  --space-22: calc(88px * var(--scaling));
  --space-23: calc(92px * var(--scaling));
  --space-24: calc(96px * var(--scaling));
  --space-25: calc(100px * var(--scaling));
  --space-26: calc(104px * var(--scaling));
  --space-27: calc(108px * var(--scaling));
  --space-28: calc(112px * var(--scaling));
  --space-29: calc(116px * var(--scaling));
  --space-30: calc(120px * var(--scaling));
  --space-31: calc(124px * var(--scaling));
  --space-32: calc(128px * var(--scaling));
  --space-33: calc(132px * var(--scaling));
  --space-34: calc(136px * var(--scaling));
  --space-35: calc(140px * var(--scaling));
  --space-36: calc(144px * var(--scaling));
  --space-37: calc(148px * var(--scaling));
  --space-38: calc(152px * var(--scaling));
  --space-39: calc(156px * var(--scaling));
  --space-40: calc(160px * var(--scaling));
  --space-41: calc(164px * var(--scaling));
  --space-42: calc(168px * var(--scaling));
  --space-43: calc(172px * var(--scaling));
  --space-44: calc(176px * var(--scaling));
  --space-45: calc(180px * var(--scaling));
}
