.business-map {
    :focus {
        outline: none;
    }
    width: 514px;
    height: 865px;
    > svg {
        width: 100%;
        height: 100%;
    }
    .local, .flag {
        cursor: pointer;
        &.active {
            .local-name {
                stroke: var(--color-error);
            }
        }
    }
    .local {
        &:hover, &:focus, &:active {
            path {
                fill: var(--accent-10);
            }
            .local-name {
                stroke: var(--accent-10);
            }
        }
        &.active {
            path {
                fill: var(--color-error);
            }
            &:hover, &:focus, &:active {
                .local-name {
                    stroke: var(--color-error);
                }
            }
        }
        path {
            fill: var(--color-primary);
        }
    }
    .local-name {
        fill: var(--font-white);
        stroke: var(--color-primary);
        paint-order: stroke;
        stroke-width: 4;
        font-size: var(--font-size-4);
        font-weight: bold;
        text-anchor: middle;
        cursor: pointer;
    }
    .flag {
        &:hover, &:focus, &:active {
            path {
                fill: var(--accent-2);
            }
        }
        &.active {
            path {
                fill: var(--bg-white);
                stroke: var(--color-error);
            }
            .number {
                fill: var(--color-error);
            }
        }
        path {
            stroke: var(--border-darken);
            stroke-width: 2;
            fill: var(--bg-white);
        }
    }
    .number {
        fill: var(--font-darken);
        font-size: var(--font-size-4);
        font-weight: bold;
        text-anchor: middle;
    }
}
.info-map {
    width: 514px;
    height: 865px;
    :focus {
        outline: none;
    }
    > svg {
        width: 100%;
        height: 100%;
    }
    .local {
        cursor: pointer;
        &:hover, &:focus, &:active {
            path {
                fill: var(--accent-10);
            }
            .local-name {
                stroke: var(--font-darken);
            }
        }
        &.active {
            path {
                fill: var(--color-error);
            }
            &:hover, &:focus, &:active {
                .local-name {
                    stroke: var(--color-error);
                }
            }
            .local-name {
                stroke: var(--color-error);
            }
            .plus {
                display: none;
            }
            .check {
                display: block;
                path {
                    fill: none;
                }
            }
        }
        path {
            fill: var(--color-primary);
        }
        .plus {
            display: block;
        }
        .check {
            display: none;
        }
    }
    .local-name {
        fill: var(--font-white);
        stroke: var(--color-primary);
        paint-order: stroke;
        stroke-width: 4;
        font-size: var(--font-size-4);
        font-weight: bold;
        text-anchor: middle;
        cursor: pointer;
    }
}

html[data-device="mobile"] {
    .business-map {
        width: 280px;
        height: 488px;
        .number {
            font-size: var(--font-size-2);
        }
        .local-name {
            font-size: var(--font-size-2);
        }
    }
    .info-map {
        width: 280px;
        height: 488px;
        .local-name {
            font-size: var(--font-size-2);
        }
    }
}