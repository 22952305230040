.visual {
    width: 100%;
    height: 352px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto 100%;
    &.introduction {
        background-image: url('../../../assets/images/common/img-visual-introduction.png');
    }
    &.policy {
        background-image: url('../../../assets/images/common/img-visual-policy.png');
    }
    &.business {
        background-image: url('../../../assets/images/common/img-visual-business.png');
    }
    &.solution {
        background-image: url('../../../assets/images/common/img-visual-solution.png');
    }
    &.practices {
        background-image: url('../../../assets/images/common/img-visual-practices.png');
    }
    &.notice {
        background-image: url('../../../assets/images/common/img-visual-notice.png');
    }
    .title {
        padding-top: calc(var(--space-35) - 2px);
        color: var(--font-white);
    }
    p {
        margin-left: var(--space-4);
        opacity: 0.6;
    }
}
[data-device='mobile'] {
    .visual {
        height: auto;
        min-height: 485px;
        background-size: cover;
        aspect-ratio: 1 / 1;
        &.introduction {
            background-image: url('../../../assets/images/common/img-visual-introduction-mobile.png');
        }
        &.policy {
            background-image: url('../../../assets/images/common/img-visual-policy-mobile.png');
        }
        &.business {
            background-image: url('../../../assets/images/common/img-visual-business-mobile.png');
        }
        &.practices {
            background-image: url('../../../assets/images/common/img-visual-practices-mobile.png');
        }
        &.solution {
            background-image: url('../../../assets/images/common/img-visual-solution-mobile.png');
        }
        &.notice {
            background-image: url('../../../assets/images/common/img-visual-notice-mobile.png');
        }
        .title {
            flex-direction: column;
            align-items: normal;
            padding-top: var(--space-12);
        }
        h2 {
            font-size: calc(var(--default-font-size) * 3);
            line-height: calc(var(--default-font-size) * 3.75);
        }
        p {
            margin-top: var(--space-1);
            margin-left: 0;
        }
    }
    @media screen and (min-width: 1080px) {
        .visual {
            height: 352px;
            min-height: auto;
            aspect-ratio: auto;
            &.introduction {
                background-image: url('../../../assets/images/common/img-visual-introduction.png');
            }
            &.policy {
                background-image: url('../../../assets/images/common/img-visual-policy.png');
            }
            &.business {
                background-image: url('../../../assets/images/common/img-visual-business.png');
            }
            &.practices {
                background-image: url('../../../assets/images/common/img-visual-practices.png');
            }
            &.solution {
                background-image: url('../../../assets/images/common/img-visual-solution.png');
            }
            &.notice {
                background-image: url('../../../assets/images/common/img-visual-notice.png');
            }
            .title {
                flex-direction: row;
                align-items: end;
                padding-top: calc(var(--space-35) - 2px);
            }
            p {
                margin-top: 0;
                margin-left: var(--space-4);
            }
        }
    }
}
