.radix-themes {
  .rt-BaseCard {
    &::before,
    &::after {
      display: none;
    }
    &:hover {
      border-color: var(--border-light);
      box-shadow: var(--shadow-4);
    }

    .rt-Inset {
      position: relative;
      margin: 0;
      --margin-top-override: 0;
      --margin-bottom-override: 0;
      --margin-left-override: 0;
      flex-shrink: 0;
      background-color: var(--sw-violet);
    }
  }
}
