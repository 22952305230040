.terms-list {
    li.sub-title3 {
        margin-bottom: var(--space-1);
        margin-top: var(--space-4);
    }
    li.body3 {
        position: relative;
        padding-left: var(--space-3);
        &::before {
            content: '-';
            position: absolute;
            top: 0;
            left: 0;
        }
    }
}
.basic-dialog {
    h1 {
        margin-bottom: var(--space-2);
    }
    p {
        font-size: var(--default-font-size);
        color: var(--font-basic);
        line-height: var(--line-height-6);
    }
    .close-btn {
        position: absolute;
        top: var(--space-2);
        right: var(--space-2);
    }
    .textarea {
        height: 130px;
    }
}

.state-dialog {
    .rt-Flex {
        p {
            flex: 0 0 20%;
        }
    }
    .rt-r-jc-center {
        padding: 0 var(--space-8);
    }
    .rt-Separator {
        background-color: var(--gray-9);
    }
    .preview-contents {
        .rt-Heading {
            display: none;
        }
        .box {
            padding: 0;
            margin-bottom: 0;
        }
    }
    .dialog-select-box {
        flex: 1;
    }
}
