.page-title {
    margin: var(--space-25) 0;
    text-align: center;
}
[data-device="mobile"] {
    .page-title {
        margin: var(--space-16) 0;
        font-size: calc(var(--default-font-size) * 2.25);
        line-height: calc(var(--default-font-size) * 3);
        text-align: left;
    }
}