.search-box {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  border: 0;
  label {
    flex-grow: 1;
  }
  .button-search {
    width: var(--space-9);
    height: var(--space-9);
    margin-left: var(--space-3);
  }
}
