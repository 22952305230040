.login-wrap {
  .page-title {
    margin-bottom: 8px;
  }
  .login-box {
    width: 400px;
    fieldset + fieldset {
      margin-top: var(--space-2);
    }
    .rt-Link {
      color: var(--font-dark);
    }
  }
}
[data-device="mobile"] {
  .login-wrap {
    overflow: hidden;
    .login-box {
      width: 100%;
    }
  }
}
