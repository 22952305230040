.error-page {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 100;
    transform: translate(-50%, -50%);
    background: var(--bg-white);

    .btn-lg {
        font-size: var(--font-size-4);
        font-weight: var(--font-weight-bold);
        line-height: calc(var(--default-font-size) * 2);
    }
}
[data-device='mobile'] {
    .error-page {
        z-index: 1002;
        padding: 0 var(--space-5);
        img {
            width: 240px;
            height: auto;
        }
        .btn-lg {
            font-size: var(--default-font-size);
        }
    }
}
