.board-item {
  cursor: pointer;
  .rt-Inset.card-thumbnail {
    background-color: var(--bg-light);
    display: 'block';
    width: 100%;
    height: 245px;
    position: relative;
    .default-img {
      width: 194px;
      height: auto;
      object-fit: contain;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .data-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .chip {
    width: var(--space-12);
    height: var(--space-12);
    color: var(--font-white);
    text-align: center;
    background: var(--color-primary);
    position: absolute;
    top: 0;
    left: 0;
    span {
      line-height: 46px;
      font-weight: var(--font-weight-bold);
    }
  }

  .card-step {
    padding: var(--space-1) var(--space-3) var(--space-1) var(--space-1);
    background: var(--bg-mustard);
    border-radius: var(--space-6);
    max-width: 120px;
    p {
      color: var(--font-white);
    }
  }

  .card-footer {
    border-block-start: 1px dashed var(--border-light);
    color: var(--font-basic);

    .view {
      display: flex;
      align-items: center;
      margin-inline-start: auto;
      &::before {
        content: '';
        display: inline-block;
        width: 24px;
        height: 24px;
        margin-inline-end: var(--space-1);
        background-image: url('../../../assets/images/icon/icon-view.svg');
      }
    }
  }

  .card-body {
    .desc {
      color: var(--font-basic);
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      line-clamp: 2;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  &.type-image-list {
    padding-block-start: var(--space-9);
    padding-block-end: var(--space-8);
    padding-inline-start: 0;
    padding-inline-end: var(--space-9);
    border-block-end: 1px solid var(--border-light);

    .chip {
      position: static;
      width: var(--space-9);
      height: var(--space-9);
      span {
        line-height: var(--space-9);
        font-weight: var(--font-weight-medium);
      }
    }

    .card-body {
      .desc {
        line-clamp: 1;
        -webkit-line-clamp: 1;
      }
    }
  }

  &.type-text-tile {
    padding: 0;
    border: 1px solid var(--border-light);
  }

  &.type-card {
    display: flex;
    flex-direction: column;
    // height: 100%;
    height: 532px;
    padding: 0;
    border: 1px solid var(--border-basic);
    &:hover {
      .card-box {
        border-color: var(--category-color);
        box-shadow: var(--shadow-3);
      }
    }
    &[data-tag='거점·기술'] {
      --category-color: var(--color-primary);
    }
    &[data-tag='인적자원'] {
      --category-color: var(--color-info);
    }
    &[data-tag='실증·보급'] {
      --category-color: var(--color-third);
    }
    &[data-tag='지역 주도 기획'] {
      --category-color: var(--color-secondary);
    }
    .card-header {
      height: 136px;
      background-color: var(--category-color);
      color: var(--font-white);
      border-bottom: 1px solid var(--border-basic);
    }
    .card-body {
      height: 278px;
      .card-contents {
        padding: 0;
        color: var(--font-basic);
        strong {
          color: var(--category-color);
        }
      }
      .sub-title {
        text-align: center;
        margin-block-end: var(--space-5);
        padding-block-end: var(--space-5);
        border-block-end: 1px dashed var(--border-basic);
      }
      .tag {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        min-height: 32px;
        margin-block-end: var(--space-2);
        padding-inline: var(--space-4);
        color: var(--font-white);
        background-color: var(--category-color);
        border-radius: 16px;
        font-size: var(--font-size-4);
        font-weight: var(--font-weight-bold);
      }
    }
    .card-footer {
      text-align: center;
      border: none;
      margin-top: auto;
      a {
        color: var(--font-basic);
        border: 1px solid var(--border-darken);
        margin-bottom: calc(var(--space-7) + 2px);
        &:hover {
          background: var(--bg-light);
        }
      }
    }
  }
}

html[data-device='mobile'] {
  .board-item {
    &.type-card {
      min-height: 444px;
    }
  }
}
