.board-image-wrap {
    .board-toggle-buttons {
        .rt-IconButton {
            opacity: 0.6;
            &:hover,
            &.active {
                opacity: 1;
            }
        }
    }

    .top-total-wrap {
        min-height: 54px;
    }

    .board-image-items {
        margin-top: calc(var(--space-1) + 2px);
        border-block-start: 2px solid var(--gray-12);
    }

    .type-image-tile {
        padding: 0;
        border: 1px solid var(--border-light);

        .rt-Inset.card-thumbnail {
            background-color: var(--bg-light);
            display: 'block';
            width: 100%;
            height: 245px;
            position: relative;
            .default-img {
                width: 194px;
                height: auto;
                object-fit: contain;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
            .data-img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .chip {
            width: var(--space-12);
            height: var(--space-12);
            color: var(--font-white);
            text-align: center;
            background: var(--color-primary);
            position: absolute;
            top: 0;
            left: 0;
            span {
                line-height: 46px;
                font-weight: var(--font-weight-bold);
            }
        }

        .card-step {
            padding: var(--space-1) var(--space-3) var(--space-1) var(--space-1);
            background: var(--bg-mustard);
            border-radius: var(--space-6);
            max-width: 120px;
            p {
                color: var(--font-white);
            }
        }

        .card-footer {
            border-block-start: 1px dashed var(--border-light);
            color: var(--font-basic);

            .view {
                display: flex;
                align-items: center;
                margin-inline-start: auto;
                &::before {
                    content: '';
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                    margin-inline-end: var(--space-1);
                    background-image: url('../../../assets/images/icon/icon-view.svg');
                }
            }
        }
    }
}

[data-device='mobile'] {
    .board-image-wrap {
        .page-title,
        .sort-by-local {
            padding: 0 var(--space-5);
        }
    }
}
