.sort-by-local {
  width: 100%;

  button {
    flex-grow: 1;
    padding: 0;
    background-color: var(--bg-light);
    color: var(--font-basic);

    &:hover {
      background-color: var(--bg-blue);
      color: var(--font-darken);
    }

    &.active {
      background-color: var(--bg-darken-blue);
      color: var(--font-white);
      box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.16);
    }
  }
}

[data-device="mobile"] {
  .sort-by-local {
    overflow: auto;
    scrollbar-width: none;

    button {
      flex-grow: 0;
      width: calc(var(--space-11) + 2px);
      height: var(--space-10);
      background-color: var(--bg-white);

      &:hover {
        background-color: var(--bg-white);
        color: var(--font-darken);
      }

      &.active {
        background-color: var(--bg-white);
        color: var(--font-darken);
        border-bottom: 2px solid var(--border-darken);
        box-shadow: unset;
      }
    }
  }
}
