.main-slider {
    position: relative;
    .slider-banner {
        position: relative;
        overflow: hidden;
    }
    .slider-image {
        position: relative;
        width: 100%;
        height: 610px;
        img {
            position: absolute;
            top: 0;
            left: 50%;
            max-width: none;
            height: 100%;
            transform: translateX(-50%);
        }
    }
    .slider-text {
        position: absolute;
        top: var(--space-15);
        left: 0;
        width: 100%;
        p {
            margin-bottom: var(--space-6);
            color: var(--font-white);
            font-size: var(--font-size-5);
            font-weight: var(--font-weight-bold);
            line-height: calc(var(--default-font-size) * 2);
            opacity: 0.64;
        }
        h2 {
            color: var(--font-white);
            font-size: calc(var(--default-font-size) * 4);
            line-height: calc(var(--default-font-size) * 4.75);
            letter-spacing: -1px;
        }
        .button-view {
            position: relative;
            margin-top: auto;
            padding-right: calc(var(--space-12) - 1px);
            color: var(--font-white);
            font-weight: var(--font-weight-bold);
            line-height: var(--line-height-6);
            &::after {
                content: '';
                position: absolute;
                top: 50%;
                right: 0;
                width: 35px;
                height: 16px;
                background: url('../../../assets/images/icon/icon-slider-view.svg') no-repeat center/ contain;
                transform: translateY(-50%);
            }
        }
    }
    .slider-inner {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 780px;
        height: 332px;
        padding-top: calc(var(--space-11) - 2px);
        padding-left: var(--space-12);
    }
    .slider-utility {
        display: flex;
        align-items: flex-end;
        position: absolute;
        bottom: var(--space-12);
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
    }
    .swiper-pagination {
        margin-right: var(--space-3);
        color: var(--font-white);
        font-size: var(--font-size-4);
        font-weight: var(--font-weight-bold);
        line-height: calc(var(--default-font-size) * 2);
        .of,
        .swiper-pagination-total {
            opacity: 0.64;
        }
    }
    .swiper-pagination-current {
        padding-right: var(--space-2);
        font-size: calc(var(--default-font-size) * 2.25);
        line-height: calc(var(--default-font-size) * 3);
        letter-spacing: -1px;
    }
    .swiper-pagination-total {
        padding-left: var(--space-1);
    }
    .slider-buttons {
        display: inline-flex;
        align-items: center;
        gap: var(--space-1);

        button {
            width: 24px;
            height: 24px;
            font-size: 0;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
        }
        .slider-prev {
            background-image: url('../../../assets/images/icon/icon-chevron-prev.svg');
        }
        .slider-next {
            background-image: url('../../../assets/images/icon/icon-chevron-next.svg');
        }
        .stop {
            background-image: url('../../../assets/images/icon/icon-stop.svg');
        }
        .play {
            background-image: url('../../../assets/images/icon/icon-play.svg');
        }
        .pause {
            background-image: url('../../../assets/images/icon/icon-pause.svg');
        }
    }
}

.banner-slider {
    position: relative;
    min-height: 530px;
    box-sizing: border-box;
    .slider-banner {
        min-height: 503px;
        height: auto;
        position: relative;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &.type-dark {
            .slider-text,
            a {
                color: var(--font-white);
            }
            .rt-Separator {
                background-color: var(--border-white);
            }
        }
        &.type-light {
            .slider-text,
            a {
                color: var(--font-darken);
                li {
                    color: var(--font-basic);
                }
            }
            .rt-Separator {
                background-color: var(--border-basic);
            }
        }
        &.bg-primary {
            background: var(--color-primary);
        }
        &.bg-navy {
            background: var(--blue-9);
        }
        &.bg-blue {
            background: var(--color-info);
        }
    }
    .slider-text {
        width: 100%;
        height: inherit;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        row-gap: var(--space-8);
        padding: var(--space-10) var(--space-6) var(--space-6) var(--space-6);
        color: var(--font-white);
        li {
            font-size: var(--default-font-size);
            font-weight: var(--font-weight-bold);
            line-height: var(--line-height-6);
            &:nth-of-type(n + 2) {
                margin-top: var(--space-1);
            }
        }
    }

    .rt-Separator {
        width: 73px;
        background-color: var(--border-white);
        opacity: 0.36;
    }

    .slider-utility {
        margin-top: var(--space-6);
    }
    .swiper-pagination {
        display: flex;
        justify-content: center;
        column-gap: var(--space-1);
        line-height: 0;
    }
    .swiper-pagination-bullet {
        width: 35px;
        height: 6px;
        background-color: var(--color-primary);
        opacity: 0.4;
    }
    .swiper-pagination-bullet-active {
        opacity: 1;
    }
}

.detail-slider {
    position: relative;
    .swiper {
        width: 100%;
        max-height: 813px;
    }
    .swiper-slide {
        img {
            width: 100%;
            height: 100%;
        }
    }
    .swiper-pagination {
        display: flex;
        align-items: baseline;
        gap: var(--space-2);
        position: absolute;
        bottom: var(--space-5);
        left: 50%;
        transform: translateX(-50%);
        color: var(--font-white);
        z-index: 2;
        & > *:not(.swiper-pagination-current) {
            opacity: 0.64;
        }
    }
    .swiper-pagination-current {
        font-weight: var(--font-weight-bold);
        font-size: var(--font-size-8);
        opacity: 1;
    }
    .arrow {
        position: absolute;
        top: 50%;
        background-color: #fff;
        cursor: pointer;
        z-index: 2;
        &.rt-r-size-2 {
            --base-button-height: var(--space-12);
        }
        &.slider-prev {
            left: 0;
            transform: translate(-50%, -50%);
            &::before {
                content: '';
                display: block;
                width: 24px;
                height: 24px;
                background-image: url(../../../assets/images/icon/icon-arrow-prev.svg);
            }
        }
        &.slider-next {
            right: 0;
            transform: translate(50%, -50%);
            &::before {
                content: '';
                display: block;
                width: 24px;
                height: 24px;
                background-image: url(../../../assets/images/icon/icon-arrow-next.svg);
            }
        }
    }
}

html[data-device='mobile'] {
    .main-slider {
        .slider-banner {
            overflow: hidden;
        }
        .slider-image {
            height: 714px;
        }
        .slider-text {
            top: 0;
            p {
                font-size: var(--font-size-4);
            }
            h2 {
                font-size: calc(var(--default-font-size) * 2.25);
                line-height: calc(var(--default-font-size) * 3);
            }
            .button-view {
                margin-top: var(--space-12);
            }
        }
        .slider-inner {
            margin-top: var(--space-12);
            padding: 0;
            width: 100%;
            height: auto;
        }
    }
    .detail-slider {
        .arrow {
            position: static;
            transform: none;
        }
        .swiper-pagination {
            position: static;
            transform: none;
            color: var(--font-basic);
        }
        .swiper-pagination-current {
            font-size: var(--font-size-6);
        }
    }
}
