#skipnav {
    a {
        display: block;
        overflow: hidden;
        position: absolute;
        left: 0;
        top: -10000px;
        
        &:hover, &:focus, &:active {
            top: 0;
            padding: 1rem 0;
            width: 100%;
            background-color: var(--bg-darken);
            color: var(--font-white);
            text-align: center;
            z-index: 100;
        }
    }
}

[data-device="mobile"] {
    #skipnav {
        display: none;
    }
}