.board-counter {
    .choose {
        color: var(--font-color-point);
    }
}
.board-toggle-buttons {
    .rt-IconButton {
        opacity: 0.6;
        &.rt-r-size-2 {
            --base-button-height: var(--space-9);
        }
        &:hover,
        &.active {
            opacity: 1;
        }
    }
}

.post-title {
    border-block-start: 2px solid var(--gray-12);
}

.post-info {
    width: 100%;
    background-color: var(--bg-light);
    color: var(--font-basic);
}

.post-box {
    border-block-start: 1px solid var(--border-light);
    border-block-end: 1px solid var(--border-light);
    .post-content {
        color: var(--font-basic);
    }
}

.post-attachment {
    .attachment-title {
        color: var(--font-darken);

        .icon-clip {
            &::after {
                content: '';
                display: inline-block;
                width: 24px;
                height: 24px;
                background-image: url(../../../assets/images/icon/icon-clip.svg);
            }
        }
    }

    .rt-Button {
        height: 38px;
        gap: var(--space-2);
        font-weight: var(--font-weight-bold);
    }
}

.attachment-menu {
    .rt-Button.rt-r-size-2 {
        padding-inline: 0;
    }
}

.attachment-list {
    border: none;
    .list-item {
        width: 100%;
        border: 1px solid var(--border-light);
        &:hover {
            border-color: var(--color-primary);
            background-color: var(--bg-light);
            a {
                color: inherit;
            }
        }
        a {
            color: var(--font-basic);
            flex: 1;
        }
        .rt-BaseCheckboxRoot {
            --checkbox-size: var(--space-6);
            --checkbox-indicator-size: var(--font-size-3);
            &[data-state='checked']::before {
                background-color: transparent;
                border: 1px solid var(--accent-indicator);
            }
            .rt-BaseCheckboxIndicator {
                background-image: url(../../../assets/images/icon/icon-check.svg);
            }
        }
        .icon {
            display: inline-block;
            width: 36px;
            height: 36px;
            &::before {
                content: '';
                display: block;
                width: 100%;
                height: 100%;
            }
            &.icon-pdf {
                &::before {
                    background-image: url(../../../assets/images/icon/icon-pdf.svg);
                }
            }
            &.icon-xls {
                &::before {
                    background-image: url(../../../assets/images/icon/icon-xls.svg);
                }
            }
            &.icon-png {
                &::before {
                    background-image: url(../../../assets/images/icon/icon-png.svg);
                }
            }
            &.icon-jpg {
                &::before {
                    background-image: url(../../../assets/images/icon/icon-jpg.svg);
                }
            }
            &.icon-jpeg {
                &::before {
                    background-image: url(../../../assets/images/icon/icon-jpg.svg);
                }
            }
            &.icon-zip {
                &::before {
                    background-image: url(../../../assets/images/icon/icon-zip.svg);
                }
            }
            &.icon-xslx {
                &::before {
                    background-image: url(../../../assets/images/icon/icon-xls.svg);
                }
            }
            &.icon-doc {
                &::before {
                    background-image: url(../../../assets/images/icon/icon-doc.svg);
                }
            }
            &.icon-hwp {
                &::before {
                    background-image: url(../../../assets/images/icon/icon-hwp.svg);
                }
            }
            &.icon-hwpx {
                &::before {
                    background-image: url(../../../assets/images/icon/icon-hwp.svg);
                }
            }
            &.icon-pptx {
                &::before {
                    background-image: url(../../../assets/images/icon/icon-pptx.svg);
                }
            }
            &.icon-ppt {
                &::before {
                    background-image: url(../../../assets/images/icon/icon-pptx.svg);
                }
            }
        }
        .rt-IconButton {
            &.rt-r-size-2 {
                --base-button-height: var(--space-6);
            }
        }
    }
}

.post-navigation {
    border-block-start: 1px solid var(--border-darken);
    .post-navigation-item {
        display: flex;
        align-items: center;
        height: 70px;
        padding-inline: var(--space-9);
        border-block-end: 1px solid var(--border-light);
        color: var(--font-basic);
        cursor: pointer;
        & > span {
            display: inline-block;
            width: 100px;
            color: var(--font-darken);
            font-size: var(--font-size-4);
        }
    }
    .rt-IconButton {
        &::before {
            filter: brightness(1.8);
        }
    }

    a {
        color: var(--font-basic);
        text-decoration: none;
    }
}

.register {
    width: 194px;
    height: 68px;
    cursor: pointer;
}

html[data-device='mobile'] {
    .post-navigation {
        position: relative;
        border-block-start: none;
        .rt-IconButton {
            width: 48px;
            height: 48px;
            &[title='이전'] {
                &::before {
                    content: '';
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                    background-image: url(../../../assets/images/icon/icon-arrow-prev.svg);
                }
            }
            &[title='다음'] {
                &::before {
                    content: '';
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                    background-image: url(../../../assets/images/icon/icon-arrow-next.svg);
                }
            }
        }
        .register {
            width: 110px;
            height: 48px;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}
