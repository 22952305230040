.radix-themes {
    .rt-IconButton {
        &.rt-variant-text {
            aspect-ratio: 1 / 1;
        }
        &.rt-r-size-1 {
            --base-button-height: var(--space-6);
        }
        &.rt-r-size-2 {
            --base-button-height: var(--space-9);
        }
        &.icon-list {
            &::before {
                content: '';
                display: block;
                width: 24px;
                height: 24px;
                background: url('../../../images/icon/icon-list.svg') no-repeat center / contain;
            }
        }
        &.icon-tile {
            &::before {
                content: '';
                display: block;
                width: 24px;
                height: 24px;
                background: url('../../../images/icon/icon-tile.svg') no-repeat center / contain;
            }
        }
    }
}
