.radix-themes {
  --cursor-button: pointer;
  --cursor-checkbox: pointer;
  --cursor-disabled: not-allowed;
  --cursor-link: pointer;
  --cursor-menu-item: pointer;
  --cursor-radio: default;
  --cursor-slider-thumb: default;
  --cursor-slider-thumb-active: default;
  --cursor-switch: default;
}
