.about-dreams {
  .page-title {
    margin-bottom: var(--space-5);
  }
  .color-primary {
    color: var(--color-primary);
  }
  .title-desc {
    text-align: center;
    color: var(--font-color-basic);
  }
  .sub-page-title {
    text-align: center;
    margin-block: calc(var(--space-5) * 5);
    color: var(--font-color-basic);
  }

  .activator-box {
    padding-top: calc(var(--space-5) * 5 + var(--space-1));
    padding-bottom: calc(var(--space-5) * 7 + var(--space-1));
    padding-inline: var(--space-14);
    background-color: var(--bg-lighten);
    .title-circle {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 338px;
      margin-inline: auto;
      border-radius: 100%;
      border: 1px dashed var(--color-primary);
      aspect-ratio: 1/1;

      ::after {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        width: 152px;
        height: 135px;
        background-image: url("../../../assets/images/pages/introduction/img-about-dreams-cloud.svg");
      }

      .title {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 300px;
        text-align: center;
        background-color: var(--color-primary);
        box-shadow: var(--shadow-4);
        box-shadow: var(--shadow-5);
        color: var(--font-white);
        border-radius: 100%;
        aspect-ratio: 1/1;
      }
    }
    .trees {
      margin-top: calc(var(--space-5) * -1);
      .root {
        .branch {
          margin-inline: auto;
          height: var(--space-11);
        }
      }
      .branches {
        display: flex;
        justify-content: space-between;
        width: 894px;
        height: 1px;
        margin-inline: auto;
        border-top: 1px dashed var(--color-primary);
        .branch {
          position: relative;
          &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 100%);
            width: var(--space-3);
            height: var(--space-3);
            border-radius: 100%;
            border: 2px solid var(--color-primary);
            background-color: var(--bg-white);
          }
        }
      }
      .branch {
        width: 1px;
        height: var(--space-6);
        border-right: 1px dashed var(--color-primary);
      }
    }
    .cards {
      display: flex;
      justify-content: space-between;
      margin-top: var(--space-7);
      .card {
        width: 394px;
        height: 372px;
        background-color: var(--bg-white);
        border: 1px solid var(--color-primary);
        .card-header {
          position: relative;
          text-align: center;
          padding-top: var(--space-12);
          padding-bottom: var(--space-6);
          &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 75px;
            height: 1px;
            background-color: var(--border-light);
          }
        }
        .card-body {
          padding-block: var(--space-6);
          color: var(--font-basic);
        }
      }
    }
  }
}

html[data-device="mobile"] {
  .about-dreams {
    .page-title {
      margin-bottom: var(--space-8);
    }
    .title-desc {
      text-align: left;
    }
    .sub-page-title {
      text-align: left;
      margin-top: var(--space-8);
    }
    .activator-box {
      padding: 0;
      background-color: transparent;
      .title-circle {
        width: 100%;
        max-width: 338px;
        .title {
          width: 100%;
          max-width: 300px;
        }
      }
      .cards {
        flex-direction: column;
        .card {
          width: 100%;
          height: auto;
          margin-top: var(--space-12);
          padding-inline: var(--space-4);
          padding-bottom: var(--space-12);
        }
      }
    }
  }
}
