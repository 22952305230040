.aside-wrap {
  min-width: 260px;
  max-width: 260px;
  .aside-title {
    border-top: 4px solid var(--border-darken);
    border-bottom: 1px solid var(--border-darken);
    p {
      font-size: var(--font-size-5);
      font-weight: var(--font-weight-bold);
      line-height: calc(var(--line-height-1) * 2);
    }
  }

  .aside-menu {
    .aside-menu-item {
      position: relative;
      margin-bottom: var(--space-3);
      &:last-child {
        margin-bottom: 0;
      }

      a {
        color: var(--font-basic);
        padding-left: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        line-clamp: 2;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        &::before {
          content: '';
          display: block;
          width: 4.5px;
          height: 4.5px;
          background: var(--bg-dark);
          position: absolute;
          top: 14px;
          left: 0;
        }

        &:hover:not(.active) {
          color: var(--font-darken);
          &::before:not(.active) {
            background: var(--bg-darkness);
          }
        }
      }

      .active {
        color: var(--color-primary);
        &::before {
          width: var(--space-1);
          height: var(--space-8);
          top: 0;
          background: var(--color-primary);
        }
      }
    }
  }
}
