.pagination {
    .rt-IconButton,
    .rt-BaseButton {
        width: var(--space-12);
        height: var(--space-12);
        background-color: transparent;
        color: var(--font-basic);
        cursor: pointer;
        &.rt-variant-text {
            margin: 0;
        }
        &:disabled {
            pointer-events: none;
        }
    }
    .rt-IconButton {
        border: 1px solid var(--border-light);
        &:hover:not(:disabled) {
            border-color: var(--border-darken);
            color: var(--border-darken);
        }
        &:disabled {
            background: var(--bg-light);
            border-color: var(--border-basic);
            color: var(--border-basic);
        }
    }
    .pages {
        display: flex;
        align-items: center;
        margin-inline: var(--space-6);
        .rt-variant-text {
            color: var(--font-basic);
            &:hover:not(.currentPage) {
                color: var(--font-color-darken);
            }
            &.currentPage {
                color: var(--font-color-darken);
            }
        }
        .rt-BaseButton {
            font-size: var(--default-font-size);
            &:disabled {
                background: transparent;
                border: none;
                color: var(--font-disabled);
            }
        }
    }
}
[data-device='mobile'] {
    .pagination {
        .rt-IconButton,
        .rt-BaseButton {
            width: var(--space-8);
            height: var(--space-8);
        }
        .pages {
            margin-inline: var(--space-2);
        }
    }
}
