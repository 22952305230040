.propulsion-system {
  .organization {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--space-12);
    padding-bottom: var(--space-20);
    border-bottom: 1px solid var(--border-light);

    img {
      max-width: 100%;
    }

    .title-wrap {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 278px;
      border-radius: 100%;
      border: 1px dashed var(--color-info);
      aspect-ratio: 1/1;
      .title {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 246px;
        text-align: center;
        background-color: var(--color-third);
        box-shadow: var(--shadow-4);
        border-radius: 100%;
        color: var(--font-white);
        aspect-ratio: 1/1;
      }
    }

    .combine {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: var(--space-20);
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: -1;
        transform: translate(-50%, -50%);
        width: 196px;
        height: 312px;
        background-image: url("../../../assets/images/pages/policy/img-flow-stem.svg");
        background-repeat: no-repeat;
      }

      .mo-stem {
        display: none;
      }

      .box-wrap {
        .box {
          padding: var(--space-8) var(--space-20);
          color: var(--font-white);

          &.primary {
            background-color: var(--color-primary);
          }

          &.blue {
            background-color: var(--color-info);
          }

          &.sky-blue {
            background-color: var(--bg-light-blue);
            color: var(--color-info);
            border: 1px solid var(--color-info);
            white-space: nowrap;
          }
        }
      }

      .stem {
        display: block;
        width: 100px;
        height: 168px;
      }

      .flex {
        display: flex;
        align-items: center;
      }

      .consulting {
        padding-top: var(--space-5);
      }
      .reviewCommittee {
        padding-bottom: var(--space-12);
      }
    }
  }
  .area {
    padding: 0 var(--space-9);
    margin-top: var(--space-20);

    max-width: 100%;

    .details {
      display: flex;
      gap: var(--space-13);

      .header {
        color: var(--font-darken);
        max-width: 248px;

        // font-size: 36px;
        // font-weight: 700;
        // line-height: 3rem;
        // letter-spacing: -0.0625rem;
      }

      .department {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: var(--space-9);
      }
    }
  }
}

html[data-device="mobile"] {
  .propulsion-system {
    .organization {
      padding-bottom: var(--space-16);
    }
    .title-wrap {
      width: 234px;
      .title {
        width: 208px;
      }
    }
    .combine {
      align-items: stretch;
      width: 100%;
      gap: var(--space-12);
      &::before {
        top: var(--space-18);
        transform: translateX(-50%);
        background-image: url("../../../assets/images/pages/policy/img-flow-stem-mo.svg");
      }
      .box-wrap {
        .box {
          width: 100%;
          text-align: center;
          padding: var(--space-5) var(--space-16);
          &.sky-blue {
            white-space: wrap;
          }
        }
      }
    }
    .area {
      margin-top: var(--space-16);
      padding-inline: 0;
      .details {
        flex-direction: column;
        gap: var(--space-9);
        .header {
          max-width: unset;
        }
      }
    }
  }
}
