.radix-themes {
    .rt-TableRootTable {
        --table-cell-min-height: var(--space-18);
        border-inline: none;
        border-block-start: 2px solid var(--border-darken);

        .rt-TableHeader {
            font-size: var(--font-size-5);
            font-weight: var(--font-weight-bold);
            background: var(--bg-light);
            .rt-TableRow {
                --table-row-box-shadow: inset 0 -1px var(--gray-9);
            }
            .rt-TableColumnHeaderCell {
                position: relative;
                &:not(:first-child)::before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 0;
                    width: 1px;
                    height: 24px;
                    background-color: var(--border-light);
                    transform: translateY(-50%);
                }
            }
        }

        .rt-TableBody {
            font-size: var(--font-size-4);
            .rt-TableRow {
                .rt-TableCell {
                    color: var(--font-basic);
                    padding-block: var(--space-5);
                    &:has(a) {
                        padding-inline: var(--space-9);
                    }
                    &.has-detail {
                        cursor: pointer;
                    }
                }
                a {
                    display: block;
                    width: 100%;
                    text-align: left;
                }
            }
        }
    }
}
