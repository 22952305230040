.solution-infographic-wrap {
  width: 100%;
  margin-left: auto;

  div,
  li,
  a,
  p,
  span {
    font-family: var(--default-font-family);
  }

  .solution-infographic-title {
    width: 100%;
    height: 360px;
    gap: calc(var(--space-2) + 2px);
    background: var(--sw-violet);
    h3,
    p {
      max-width: 600px;
      color: var(--font-white);
      word-break: keep-all;
    }
  }

  .solution-infographic-bg {
    &.gray {
      background: var(--bg-light);
    }
    &.light-sky {
      background: var(--bg-light-sky);
    }
    &.sky {
      background: var(--bg-sky);
    }
  }

  .solution-infographic-contents {
    background: var(--bg-white);

    .info-items {
      padding: var(--space-10);
    }
    .info-items-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: var(--space-2);
      margin-bottom: var(--space-10);
      .agenda-number {
        font-family: var(--agenda-font-family-medium);
        font-size: calc(var(--default-font-size) * 4);
        line-height: calc(var(--default-font-size) * 4.75);
        letter-spacing: -1px;
      }
      .info-title {
        width: 100%;
        text-align: right;
        position: relative;
        font-size: calc(var(--default-font-size) * 2.5);
        font-weight: var(--font-weight-bold);
        &::after {
          content: '';
          display: block;
          width: 100%;
          border-top: 2px solid var(--bg-dark);
          position: absolute;
          bottom: 10px;
        }
      }
    }

    .info-conts-box {
      padding-left: var(--space-8);
    }

    .info-card-wrap {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: var(--space-6);
    }
    .info-card {
      width: 100%;
      .info-card-title {
        background: var(--bg-dark-blue);
        color: var(--font-white);
        border-top-left-radius: var(--space-2);
        border-top-right-radius: var(--space-2);
        p {
          font-size: calc(var(--default-font-size) * 1.5);
          font-weight: var(--font-weight-bold);
          line-height: calc(var(--default-font-size) * 2.25);
        }
      }
      .info-card-desc {
        padding: var(--space-5) var(--space-6);
        border: 1px solid var(--border-light);
        border-top: none;
        border-bottom-left-radius: var(--space-2);
        border-bottom-right-radius: var(--space-2);
        p {
          font-size: var(--font-size-4);
          line-height: calc(var(--default-font-size) * 2);
          margin: 0 auto;
        }
        &.flex {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 10px;
        }
      }
      &.horizon {
        .info-card-title {
          min-width: 193px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          border-top-right-radius: unset;
          border-bottom-left-radius: var(--space-2);
        }
        .info-card-desc {
          width: 100%;
          border-top: 1px solid var(--border-light);
          border-bottom-left-radius: unset;
          border-top-right-radius: var(--space-2);
          p {
            margin: 0;
          }
        }
      }
    }

    .info-flex-box {
      display: flex;
      align-items: center;
      gap: var(--space-10);
      &.gap-sm {
        gap: var(--space-6);
      }
      &.col {
        flex-direction: column;
      }
    }

    .info-text-box {
      width: 100%;
      .subject {
        font-size: calc(var(--default-font-size) * 1.5);
        font-weight: var(--font-weight-bold);
        line-height: calc(var(--default-font-size) * 2.25);
        word-break: keep-all;
        margin-bottom: var(--space-4);
      }
      .summary {
        font-size: var(--font-size-4);
        line-height: calc(var(--default-font-size) * 2);
        word-break: keep-all;
      }
      & + .info-text-box {
        margin-top: var(--space-3);
      }
      &.align-start {
        align-self: flex-start;
      }
    }

    .info-badge-list {
      align-items: center;
      display: flex;
      gap: var(--space-2);
      flex-direction: row;
      flex-wrap: wrap;
      .info-badge {
        border: 1px solid var(--border-basic);
        background: var(--bg-light);
        border-radius: var(--space-1);
        span {
          font-size: var(--font-size-4);
          line-height: calc(var(--default-font-size) * 2);
        }
      }
    }

    .info-personal-box {
      display: flex;
      padding: var(--space-8);
      gap: var(--space-10);
      border-radius: var(--space-2);
      .personal-item {
        display: flex;
        align-items: flex-start;
        .title {
          min-width: 140px;
          margin-left: var(--space-1);
          font-size: var(--font-size-4);
          font-weight: var(--font-weight-bold);
          line-height: calc(var(--default-font-size) * 2);
          position: relative;
          &::after {
            content: '';
            display: inline-block;
            width: 2px;
            height: var(--space-3);
            background: #8aa3a1;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }
        .desc {
          max-width: 414px;
          font-size: var(--font-size-4);
          line-height: calc(var(--default-font-size) * 2);
          padding-left: var(--space-4);
        }
      }
      &.mint {
        background: var(--sw-mint);
      }
      &.navy {
        background: var(--sw-violet);
      }
    }

    .info-logo-wrap {
      width: 258px;
      &.info-logo-wrap {
        margin-right: var(--space-5);
      }
      .info-logo {
        min-height: 122px;
        padding: var(--space-5);
        border: 1px solid var(--border-light);
        border-radius: var(--space-2);
        text-align: center;
      }
      .info-logo-name {
        font-size: var(--default-font-size);
        font-weight: var(--font-weight-bold);
        line-height: var(--line-height-6);
        text-align: center;
      }
    }
    .info-attach-wrap {
      .info-outline-box {
        min-height: unset;
        padding: var(--space-4);
      }
      a {
        font-size: var(--font-size-4);
        line-height: calc(var(--default-font-size) * 2);
        text-decoration: underline;
        color: var(--font-darken);
      }
      p {
        font-size: var(--font-size-4);
        line-height: calc(var(--default-font-size) * 2);
        word-break: keep-all;
      }
      button {
        width: 36px;
        height: 36px;
        aspect-ratio: 1 / 1;
        margin-left: auto;
      }
    }

    .info-outline-box {
      width: 100%;
      padding: var(--space-4) var(--space-4) 0 var(--space-4);
      border: 1px solid var(--border-light);
      border-radius: var(--space-2);
      p,
      span {
        word-break: keep-all;
      }

      &.height-lg {
        min-height: 386px;
      }

      &.no-radius {
        border-radius: unset;
      }
    }

    .info-head-title {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: var(--space-10);
      .title {
        max-width: 352px;
        margin-bottom: var(--space-6);
        font-size: calc(var(--default-font-size) * 2.25);
        font-weight: var(--font-weight-bold);
        line-height: calc(var(--default-font-size) * 3);
        word-break: keep-all;
      }
      .desc {
        max-width: 605px;
        font-size: var(--default-font-size);
        font-weight: var(--font-weight-bold);
        line-height: var(--line-height-6);
        word-break: keep-all;
      }
      .img-box {
        width: 280px;
        height: 280px;
        padding: var(--space-10) 0;
        text-align: center;
      }
    }

    .info-amount-box {
      display: flex;
      align-items: center;
      padding: var(--space-11) 0 var(--space-11) var(--space-11);

      .info-amount-item {
        padding-right: var(--space-15);
        margin-bottom: var(--space-4);
        & + .info-amount-item {
          border-left: 1px solid var(--border-darken);
          padding-left: var(--space-15);
        }
        p {
          font-size: calc(var(--default-font-size) * 1.5);
          font-weight: var(--font-weight-bold);
          line-height: calc(var(--default-font-size) * 2.25);
        }
        .agenda-number {
          font-family: var(--agenda-font-family-bold);
          font-size: calc(var(--default-font-size) * 3);
          line-height: calc(var(--default-font-size) * 3.86);
          letter-spacing: -1px;
        }
      }
      .info-primary {
        color: var(--color-primary);
      }
    }
    .unit {
      width: 100%;
      margin-bottom: var(--space-4);
      text-align: right;
    }
    .info-pie-chart {
      position: relative;
      width: 178px;
      height: 178px;
      border-radius: 50%;
      margin-left: var(--space-10);
      .pie-chart-center {
        width: 80px;
        height: 80px;
        background: var(--bg-white);
        border-radius: 50%;
      }
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .info-best-wrap {
      align-items: stretch;
      .info-outline-box {
        width: calc(calc(100% / 3) - 16px);
        min-height: 176px;
        padding: var(--space-8);
        p {
          font-size: calc(var(--default-font-size) * 1.5);
          font-weight: var(--font-weight-bold);
          line-height: calc(var(--default-font-size) * 2.25);
        }
        span {
          font-size: var(--font-size-4);
          line-height: calc(var(--default-font-size) * 2);
        }
      }
    }

    .info-performance-wrap {
      justify-content: center;
      gap: var(--space-10);
      padding: var(--space-11) 0;
      width: 100%;
      .info-performance {
        text-align: center;
        p {
          font-size: var(--font-size-4);
          font-weight: var(--font-weight-bold);
          line-height: 1.4;
        }
      }
      .info-performance-num {
        position: relative;
        p {
          min-width: 50px;
          max-width: 80px;
          padding: 0 var(--space-2);
          min-height: 26px;
          border: 1px solid var(--border-darken);
          background: var(--bg-white);
          border-radius: var(--space-1);
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        .info-red {
          color: var(--red-9);
        }
        .info-teal {
          color: var(--color-primary);
        }
        .info-blue {
          color: var(--color-info);
        }
      }
    }

    .info-img {
      img {
        width: 100%;
        height: 100%;
      }
      &.bulb {
        width: 123px;
        height: 120px;
        background: url('../../assets/images/pages/solution/icon-info-bulb.png') no-repeat right center / contain;
      }
      &.expected {
        width: 170px;
        height: 230px;
        background: url('../../assets/images/pages/solution/icon-info-expected.png') no-repeat right bottom / contain;
      }
      &.plan {
        width: 200px;
        height: 217px;
        background: url('../../assets/images/pages/solution/icon-info-plan.png') no-repeat right bottom / contain;
      }
      &.personal {
        width: 218px;
        height: 200px;
        background: url('../../assets/images/pages/solution/icon-info-personal.png') no-repeat left top / contain;
      }
      &.position {
        width: 136px;
        height: 120px;
        margin-left: auto;
      }
      &.direction {
        width: 200px;
        height: 280px;
        background: url('../../assets/images/pages/solution/icon-info-direction.svg') no-repeat center / contain;
      }
    }

    .info-calendar-wrap {
      padding: var(--space-5);
      column-gap: var(--space-13);
      row-gap: var(--space-6);
      grid-template-columns: repeat(4, 1fr);
      .info-calendar-box {
        width: 190px;
        height: 180px;
        position: relative;
        background: url('../../assets/images/pages/solution/icon-info-calendar.svg') no-repeat center / contain;
        .info-calendar-title {
          color: var(--font-white);
          position: absolute;
          top: calc(var(--space-5) + 2px);
          left: 50%;
          transform: translateX(-50%);
          font-size: calc(var(--default-font-size) * 1.5);
          font-weight: var(--font-weight-bold);
          line-height: calc(var(--default-font-size) * 2.25);
        }
        .info-calendar-contents {
          position: absolute;
          top: calc(var(--space-20) + 2px);
          left: 50%;
          transform: translateX(-50%);
          word-break: keep-all;
          font-size: var(--font-size-4);
          line-height: calc(var(--default-font-size) * 2);
        }
      }
    }

    .info-guide-title {
      width: 100%;
      min-height: var(--space-13);
      border-top-left-radius: var(--space-2);
      border-top-right-radius: var(--space-2);
      background: var(--bg-dark-blue);
      padding: var(--space-2) var(--space-4);

      p {
        color: var(--font-white);
        &.agenda-number5 {
          padding-top: var(--space-2);
        }
      }
    }
    .info-guide-sub {
      width: 100%;
      min-height: 40px;
      p {
        color: var(--font-white);
        word-break: keep-all;
      }
      &.primary {
        background: var(--color-primary);
      }
      &.blue {
        background: var(--color-info);
      }
      &.orange {
        background: var(--color-error);
      }
    }
    .info-guide-contents {
      margin-bottom: 40px;
      li {
        margin-bottom: var(--space-2);
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .info-arrow-box {
      width: 30px;
      height: 30px;
      border-radius: 5px;
      text-align: center;
      padding: var(--space-2) 0;
      &.blue {
        background: var(--color-info);
      }
      &.primary {
        background: var(--color-primary);
      }
      &.violet {
        background: var(--bg-violet);
      }
      &.gray {
        background: var(--bg-gray);
      }
    }
    .info-guide-box {
      min-width: 436px;
      .info-guide-item {
        padding: var(--space-2);
        & + .info-guide-item {
          margin-top: var(--space-2);
        }
        &.sky {
          background: var(--bg-sky);
        }
        &.mint {
          background: var(--sw-mint);
        }
        &.violet {
          background: var(--bg-light-violet);
        }
        &.gray {
          background: var(--bg-light-gray);
        }

        p {
          word-break: keep-all;
          flex: 1;
        }
      }
    }
    .info-chip {
      min-width: 38px;
      padding: 2px var(--space-3);
      border-radius: var(--space-5);
      border: none;
      color: var(--font-white);
      margin-right: var(--space-2);
      &.isolation {
        display: flex;
        align-items: center;
        min-width: unset;
        width: var(--space-6);
        height: var(--space-6);
        margin-right: var(--space-1);
        border-radius: 50%;
        position: relative;
        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        & + p {
          &.navy {
            color: var(--bg-navy);
          }
          &.primary {
            color: var(--color-primary);
          }
          &.info {
            color: var(--color-info);
          }
        }
      }

      &.blue {
        background: var(--bg-darken-blue);
      }
      &.mint {
        background: var(--teal-10);
      }
      &.violet {
        background: var(--bg-violet);
      }
      &.gray {
        background: var(--bg-gray);
      }
      &.navy {
        background: var(--bg-navy);
      }
      &.primary {
        background: var(--color-primary);
      }
      &.info {
        background: var(--color-info);
      }
    }
    .info-guide-process {
      flex: 1;
      background: var(--bg-sky);
      text-align: center;
      .info-process-box {
        background: var(--bg-light-sky);
        border-radius: var(--space-2);
      }
      li {
        border: 1px solid var(--border-white);
        border-radius: var(--space-10);
        box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.12);
        padding: var(--space-3) 0;
        margin-bottom: var(--space-13);
        &:last-child {
          margin-bottom: 0;
        }

        &.mint {
          background: var(--sw-mint);
        }
        &.violet {
          background: var(--bg-light-violet);
        }
        &.gray {
          background: var(--bg-light-gray);
        }
      }
    }
    .info-dots-list {
      min-height: var(--space-14);
      li {
        position: relative;
        padding-left: calc(var(--space-4) + 1px);
        margin-bottom: var(--space-1);
        &:last-child {
          margin-bottom: 0;
        }
        &::before {
          content: '';
          display: block;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background: var(--bg-dark);
          position: absolute;
          top: 10px;
          left: 2px;
        }
      }
    }
  }
}

[data-device='mobile'] {
  .solution-infographic-wrap {
    .solution-infographic-title {
      gap: var(--space-5);
      min-height: 438px;
      height: max-content;
      h6,
      p {
        max-width: unset;
        color: var(--font-white);
      }
    }

    .solution-infographic-contents {
      .info-items {
        padding: var(--space-5) var(--space-3);
      }
      .info-items-title {
        align-items: flex-start;
        margin-bottom: var(--space-5);
        .agenda-number {
          font-family: var(--agenda-font-family-medium);
          font-size: calc(var(--default-font-size) * 2.25);
          line-height: calc(var(--default-font-size) * 2.68);
          letter-spacing: -1px;
        }
        .info-title {
          font-size: var(--font-size-6);
          &::after {
            bottom: 0;
          }
        }
      }

      .info-conts-box {
        padding-left: 0;
      }

      .info-flex-box {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--space-5);
      }

      .info-card-wrap {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
      }
      .info-card {
        .info-card-title {
          height: 56px;
          p {
            font-size: calc(var(--default-font-size) * 1.25);
            font-weight: var(--font-weight-bold);
            line-height: calc(var(--default-font-size) * 2);
          }
        }
        .info-card-desc {
          padding: var(--space-5) var(--space-4);
          p {
            font-size: var(--default-font-size);
            line-height: var(--line-height-6);
          }
          &.flex {
            flex-direction: column;
            align-items: flex-start;
          }
        }
        &.horizon {
          flex-direction: column;
          .info-card-title {
            min-width: unset;
            border-top-left-radius: var(--space-2);
            border-top-right-radius: var(--space-2);
            border-bottom-left-radius: unset;
          }
          .info-card-desc {
            border-top: none;
            border-bottom-left-radius: var(--space-2);
            border-bottom-right-radius: var(--space-2);
            p {
              margin: 0;
            }
          }
        }
      }

      .info-text-box {
        max-width: unset;
        width: 100%;
        .subject {
          font-size: calc(var(--default-font-size) * 1.25);
          font-weight: var(--font-weight-bold);
          line-height: calc(var(--default-font-size) * 2);
          margin-bottom: var(--space-3);
        }
        .summary {
          font-size: var(--default-font-size);
          line-height: var(--line-height-6);
        }
      }

      .info-badge-list {
        // flex-direction: column;
        flex-wrap: wrap;
        align-items: flex-start;
        .info-badge {
          span {
            font-size: var(--default-font-size);
            line-height: var(--line-height-6);
          }
        }
      }

      .info-logo-wrap {
        width: 100%;
        &.info-logo-wrap {
          margin-right: 0;
          margin-top: var(--space-5);
        }
        .info-logo {
          min-height: 122px;
        }
        .info-logo-name {
          font-size: var(--default-font-size);
          font-weight: var(--font-weight-bold);
          line-height: var(--line-height-6);
          text-align: center;
        }
      }
      .info-attach-wrap {
        .info-outline-box {
          min-height: unset;
          display: block;
          padding: var(--space-4);
        }
        a {
          font-size: var(--default-font-size);
          line-height: var(--line-height-6);
        }
        p {
          font-size: var(--default-font-size);
          line-height: var(--line-height-6);
        }
      }

      .info-img {
        img {
          width: 100%;
          height: 100%;
        }
        &.bulb {
          width: 123px;
          height: 120px;
          background: url('../../assets/images/pages/solution/icon-info-bulb-mo.png') no-repeat right bottom / contain;
        }
        &.expected {
          width: 89px;
          height: 120px;
          background: url('../../assets/images/pages/solution/icon-info-expected-mo.png') no-repeat right bottom / contain;
        }
        &.plan {
          width: 100%;
          height: 120px;
          background: url('../../assets/images/pages/solution/icon-info-plan-mo.png') no-repeat right bottom / contain;
        }
        &.personal {
          width: 131px;
          height: 120px;
          margin: 0 auto;
          background: url('../../assets/images/pages/solution/icon-info-personal-mo.png') no-repeat center / contain;
        }
        &.position {
          width: 136px;
          height: 120px;
          margin-left: auto;
        }
        &.direction {
          width: 140px;
          height: 120px;
          background: url('../../assets/images/pages/solution/icon-info-direction.svg') no-repeat right bottom / contain;
        }
      }

      .info-personal-box {
        flex-direction: column;
        gap: var(--space-5);
        padding: var(--space-5) var(--space-4);
        .personal-item {
          .title {
            min-width: 118px;
            font-size: var(--default-font-size);
            font-weight: var(--font-weight-bold);
            line-height: var(--line-height-6);
          }
          .desc {
            max-width: 140px;
            font-size: var(--default-font-size);
            line-height: var(--line-height-6);
            padding-left: var(--space-2);
          }
        }
      }

      .info-amount-box {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        gap: 0;
        padding: 0;

        .info-amount-item {
          width: 100%;
          padding-right: 0;
          margin-bottom: 0;
          border-left: none;
          & + .info-amount-item {
            padding-left: 0;
            padding-top: var(--space-6);
            border-top: 1px solid var(--border-darken);
          }
          p {
            font-size: calc(var(--default-font-size) * 1.25);
            font-weight: var(--font-weight-bold);
            line-height: calc(var(--default-font-size) * 2);
          }
          .agenda-number {
            font-family: var(--agenda-font-family-bold);
            font-size: calc(var(--default-font-size) * 2.5);
            line-height: calc(var(--default-font-size) * 4.75);
          }
        }
      }
      .unit {
        width: 100%;
        margin-bottom: var(--space-8);
        text-align: right;
      }
      .info-pie-chart {
        align-self: center;
        margin: var(--space-5) 0 var(--space-4);
      }

      .info-best-wrap {
        flex-direction: column;
        align-items: flex-start;
        .info-outline-box {
          align-items: flex-start;
          padding: var(--space-4);
          p {
            font-size: calc(var(--default-font-size) * 1.25);
            font-weight: var(--font-weight-bold);
            line-height: calc(var(--default-font-size) * 2);
          }
          span {
            font-size: var(--default-font-size);
            line-height: var(--line-height-6);
          }
        }
      }

      .info-calendar-wrap {
        padding: 0;
        gap: var(--space-2);
        grid-template-columns: repeat(2, 1fr);
        .info-calendar-box {
          width: 153px;
          height: 148px;
          .info-calendar-title {
            top: calc(var(--space-4) + 2.5px);
            font-size: calc(var(--default-font-size) * 1.25);
            font-weight: var(--font-weight-bold);
            line-height: calc(var(--default-font-size) * 2);
          }
          .info-calendar-contents {
            width: 100%;
            max-width: 135px;
            top: var(--space-16);
            font-size: var(--default-font-size);
            line-height: var(--line-height-6);
          }
        }
      }

      .info-outline-box {
        min-height: var(--space-21);
        display: inline-flex;
        align-items: center;
        flex-direction: column;
        padding: var(--space-8) var(--space-8) 0 var(--space-8);
      }

      .info-performance-wrap {
        gap: var(--space-5);
        padding: var(--space-5) var(--space-2);
        .info-performance {
          p {
            font-size: var(--default-font-size);
            font-weight: var(--font-weight-bold);
            line-height: 1.4;
          }
        }
        .info-performance-num {
          position: relative;
          p {
            min-width: 50px;
            max-width: 60px;
            padding: 0 var(--space-1);
          }
        }
      }

      .info-head-title {
        flex-direction: column;
        gap: var(--space-5);
        .title {
          max-width: 244px;
          font-size: calc(var(--default-font-size) * 1.5);
          font-weight: var(--font-weight-bold);
          line-height: calc(var(--default-font-size) * 2.25);
        }
        .desc {
          max-width: 315px;
          font-size: var(--font-size-2);
          font-weight: var(--font-weight-bold);
          line-height: var(--line-height-4);
        }
        .img-box {
          width: 160px;
          height: 160px;
          margin-left: auto;
          padding: 0;
        }
      }

      .info-arrow-box {
        align-self: center;
      }

      .info-guide-box {
        min-width: unset;
        width: 100%;
      }

      .info-arrow-box {
        &.rotate {
          transform: rotate(90deg);
        }
      }
    }
  }
}
