.schedule {
    .schedule-chip {
        width: 103px;
        height: 48px;
        padding-block: var(--space-2);
        border-radius: var(--space-6);
        background-color: var(--color-info);
        color: var(--font-white);
    }
    .schedule-list {
        width: 100%;
        height: 100%;
        padding: var(--space-6);
        border: var(--space-7) solid var(--bg-sky);
        position: relative;
        &::before {
            content: '';
            display: inline-block;
            width: 0;
            height: 0;
            border-bottom: 58px solid transparent;
            border-left: 58px solid var(--bg-sky);
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    .schedule-item {
        height: fit-content;
        padding: var(--space-10);
        border-block-end: 1px solid var(--border-darken);
        &:last-child {
            border-block-end: none;
        }
        &.break-time {
            background: var(--bg-lighten);
        }
        .schedule-title {
            width: 295px;
            margin-right: 65px;
            min-height: inherit;
            font-size: calc(var(--default-font-size) * 1.75);
            font-weight: var(--font-weight-bold);
            line-height: calc(var(--default-font-size) * 2.5);
            img {
                margin-top: var(--space-6);
            }
        }
        .schedule-contents {
            display: flex;
            flex-direction: column;
            row-gap: var(--space-10);
            flex: 1;
            border-left: 1px solid var(--border-light);
            padding-left: var(--space-7);
            .schedule-time {
                width: 136px;
                font-size: var(--font-size-4);
                font-weight: var(--font-weight-bold);
                line-height: calc(var(--default-font-size) * 2);
            }
            &.height-lg {
                min-height: 284px;
            }
        }
        .schedule-box {
            display: flex;
            column-gap: var(--space-7);
        }
        .schedule-notime {
            img {
                visibility: hidden;
            }
        }
        .schedule-nodata {
            .schedule-time {
                width: unset;
                display: flex;
                align-items: center;
                column-gap: var(--space-7);
                p {
                    width: 136px;
                }
                div {
                    margin-top: 0;
                }
            }
        }
    }
}

[data-device='mobile'] {
    .schedule {
        margin-top: var(--space-10);
        .schedule-list {
            padding: var(--space-3);
            border: var(--space-5) solid var(--bg-sky);
            &::before {
                display: none;
            }
        }
        .schedule-item {
            padding: var(--space-5) var(--space-3);
            height: fit-content;
        }
        .schedule-title {
            width: 100%;
            min-height: 36px;
            margin-right: 0;
            padding-bottom: var(--space-5);
            border-bottom: 1px solid var(--border-light);

            font-size: calc(var(--default-font-size) * 1.5);
            font-weight: var(--font-weight-bold);
            line-height: calc(var(--default-font-size) * 2.25);

            display: flex;
            flex-direction: column;

            img {
                width: 104px;
                height: auto;
                margin-top: var(--space-4);
            }
        }
        .schedule-contents {
            width: 100%;
            row-gap: var(--space-3);
            flex: unset;
            border-left: none;
            padding-left: 0;
            padding-top: var(--space-4);
            .schedule-time {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: var(--space-4);

                img {
                    width: 28px;
                    height: auto;
                }
            }
            &.height-lg {
                min-height: unset;
            }
        }
        .schedule-box {
            width: 100%;
            display: flex;
            flex-direction: column;
            column-gap: unset;
        }
        .schedule-notime {
            .schedule-time {
                display: none;
            }
        }
        .schedule-nodata {
            .schedule-time {
                column-gap: 10px;
                margin-bottom: 0;
                p {
                    width: unset;
                }
                div {
                    margin-top: 0;
                }
            }
        }
        .schedule-subject {
            width: 100%;
            max-width: 320px;
        }
    }
}
