.quick-menu {
    position: fixed;
    bottom: var(--space-10);
    right: var(--space-7);
    z-index: 1;
    &.open {
        .go-top {
            width: 100%;
        }
    }
    .login {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 68px;
        height: 68px;
        background-color: var(--color-primary);
        color: var(--font-white);
        font-size: var(--font-size-2);
        font-weight: var(--font-weight-bold);
    }
    .go-top {
        margin-top: var(--space-2);
        width: 68px;
        height: 68px;
        border: 1px solid var(--color-primary);
        background: var(--bg-white) url('../../../assets/images/icon/icon-top.svg') no-repeat center / 24px 24px;
        font-size: 0;
    }
    .menu-warp {
        width: 134px;
        background-color: var(--bg-white);
        button {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 68px;
            background-color: var(--color-primary);
            color: var(--font-white);
            font-size: var(--font-size-2);
            font-weight: var(--font-weight-bold);
        }
    }
    .menu-inner {
        border-width: 1px 1px 0;
        border-style: solid;
        border-color: var(--border-light);
    }
    .member {
        width: 100%;
        height: 68px;
        background: var(--bg-light) url('../../../assets/images/icon/icon-member.svg') no-repeat center / 24px 24px;
        font-size: 0;
    }
    .info {
        padding: var(--space-5) 0;
        color: var(--font-basic);
        text-align: center;
    }
    .menu-items {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: var(--space-5) 0;
        background-color: var(--bg-white);
        a {
            display: block;
            color: var(--font-light);
            font-size: var(--font-size-2);
            font-weight: var(--font-weight-medium);
            line-height: calc(var(--default-font-size) * 1.375); // 22px
            &:nth-of-type(n + 2) {
                margin-top: var(--space-3);
            }
            &.active {
                color: var(--font-darken);
            }
        }
    }
}

[data-device='mobile'] {
    .quick-menu {
        display: none;
    }
}
