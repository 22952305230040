.radix-themes {
    --container-4: 1400px;
    .rt-ContainerInner {
        // 임시
        min-width: 1280px;
    }
}

html[data-device='mobile'] {
    .radix-themes {
        --container-4: 100%;
    }
    .rt-ContainerInner {
        padding-inline: var(--space-5);
        min-width: unset;
    }
    .px-0 {
        .rt-ContainerInner {
            padding-inline: 0;
        }
    }
}
