.sort-by-year {
  width: 100%;
  button {
    flex-grow: 1;
    padding: 0;
    height: 70px;
    border-right: none;
    &.active {
      border: none;
      background-color: var(--border-darken);
      color: var(--font-white);
      font-weight: var(--font-weight-bold);
    }
    &:last-child {
      border-right: 1px solid var(--border-light);
    }
  }
}
[data-device="mobile"] {
  .sort-by-year {
    button {
      height: 68px;
      font-size: var(--default-font-size);
      line-height: var(--line-height-6); // 30px
    }
  }
}
