.industry {
    h4 {
        margin-block-start: 100px;
        text-align: center;
        color: var(--font-darken);
        .color-primary {
            color: var(--color-primary);
        }
        .highlight {
            position: relative;
            &::before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translate(-50%);
                z-index: -1;
                width: 110%;
                height: 27px;
                background-color: var(--bg-light-mint);
            }
        }
    }

    .desc {
        text-align: center;
        margin-block-start: var(--space-8);
        color: var(--font-basic);
    }

    .life-elements {
        height: 294px;
        position: relative;
        margin-block-start: var(--space-18);
        .title-wrap {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            align-items: center;
            justify-content: center;
            width: 294px;
            background-color: var(--bg-light);
            background-image: url('../../../assets/images/pages/introduction/bg-introduction-title-circles.svg');
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            box-shadow: var(--shadow-3);
            border-radius: 100%;
            aspect-ratio: 1/1;
            .title {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 212px;
                text-align: center;
                background-color: var(--bg-white);
                box-shadow: var(--shadow-3);
                color: var(--font-darken);
                border-radius: 100%;
                aspect-ratio: 1/1;
            }
        }
    }

    .img-box {
        width: 218px;
        height: 111px;
        margin: var(--space-12) auto;
        transform: rotate(180deg);
    }

    .items {
        display: flex;
        align-items: stretch;
        gap: var(--space-4);
        .item {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            min-height: 316px;
            padding-block: var(--space-15);
            padding-inline: var(--space-12);
            text-align: left;
            color: var(--font-white);

            &.item-tech {
                background-color: var(--color-primary);
            }
            &.item-supply {
                background-color: var(--blue-9);
            }
            &.item-resource {
                background-color: var(--color-info);
            }

            dl {
                dt {
                    margin-block-end: var(--space-5);
                }
                dd {
                    position: relative;
                    padding-inline-start: var(--space-5);
                    margin-top: var(--space-1);
                    &::before {
                        content: '';
                        position: absolute;
                        top: 12px;
                        left: 0;
                        width: 8px;
                        background-color: var(--bg-white);
                        aspect-ratio: 1/1;
                    }
                    p {
                        font-size: var(--font-size-4);
                        font-weight: var(--font-weight-bold);
                        line-height: calc(var(--default-font-size) * 2);
                    }
                }
            }
        }
    }
}

html[data-device='mobile'] {
    .industry {
        h4 {
            margin-block-start: var(--space-16);
            text-align: left;
        }
        .desc {
            text-align: left;
            margin-block-start: var(--space-5);
        }
        .life-elements {
            position: relative;
            width: 100%;
            margin-block-start: var(--space-16);
            padding-block-start: calc(var(--space-5) * 7);
            .title-wrap {
                top: 0;
                transform: translateX(-50%);
            }
        }
        .items {
            flex-direction: column;
            gap: var(--space-3);
            .item {
                min-height: 308px;
                padding-block: var(--space-15);
                padding-inline: var(--space-8);
            }
        }
    }
}
