// @import 'https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400;1,700&display=swap';

@media print {
  body {
    margin: 0 !important;
  }
}

.main-container {
  /* --ckeditor5-preview-sidebar-width: 270px; */
  --ckeditor5-preview-height: 380px;
  font-family: 'Lato';
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  .editor-container_document-editor {
    .editor-container__minimap-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      background: hsl(0, 0%, 100%);

      .editor-container__editor-wrapper {
        width: 100%;
        height: var(--ckeditor5-preview-height);

        &.read-only {
          height: 100%;
        }
        display: flex;
        background: var(--bg-white);

        .editor-container__editor {
          overflow: auto;
          width: 100%;
        }

        .ck-content,
        .ck.ck-editor__editable {
          width: 100%;
          height: 100%;
          color: var(--font-basic);
          font-family: 'Lato';
          line-height: 1.6;
          word-break: break-all;
        }

        .ck.ck-editor__editable {
          box-sizing: border-box;
          width: 100%;
          height: 100%;
          padding: 0;
          flex: 1 1 auto;
        }
      }
    }
  }
}
