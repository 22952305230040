.radix-themes {
  .rt-SelectTrigger {
    gap: calc(var(--space-3) - 2px);
    padding-right: var(--space-2);
    // min-width: 100px;
    width: 100%;
    border: 1px solid var(--border-light);
    background-color: var(--bg-white);
    box-shadow: none;
    &:hover {
      border-color: var(--border-darken);
    }
    &:focus {
      border-color: var(--border-focus);
    }
    &:disabled {
      background-color: var(--bg-disabled);
      &:hover {
        border-color: var(--border-light);
      }
      .rt-SelectTriggerInner {
        color: var(--font-disabled);
      }
      svg {
        fill: var(--border-light);
      }
    }
    &.rt-r-size-2 {
      --select-trigger-height: var(--space-8); // 32px
    }
    &.rt-r-size-3 {
      --select-trigger-height: var(--space-12); // 48px
    }
    &[data-state="open"] {
      svg {
        transform: rotate(180deg);
      }
    }
    svg {
      width: 24px;
      height: 24px;
      padding: calc(var(--space-1) + 1.5px);
      fill: var(--border-darken);
      transition: var(--base-transition);
      // background: url("../../../images/icon/icon-arrow-down.svg") no-repeat
      //   center/ contain;
    }
    .rt-SelectTriggerInner {
      // color: var(--font-light);
      font-size: var(--default-font-size);
      font-weight: var(--font-weight-regular);
      line-height: var(--line-height-6);
    }
  }
  //selectbox option box
  .rt-SelectViewport {
    padding: 0;
    border: 1px solid var(--border-focus);
    border-top: 0;
  }
  //selectbox option
  .rt-SelectItem {
    padding: calc(var(--space-2) + 1px) var(--space-3);
    height: 100%;
    color: var(--font-light);
    font-size: var(--default-font-size);
    font-weight: var(--font-weight-regular);
    line-height: var(--line-height-6);
    &[data-highlighted] {
      background-color: var(--bg-light);
      color: var(--font-basic);
    }
    &[data-state="checked"] {
      background-color: var(--select-option-selected);
      color: var(--color-primary);
      &[data-highlighted] {
        background-color: var(--bg-light);
      }
    }
    &[data-disabled] {
      color: var(--select-option-disabled);
      &:hover {
        background-color: transparent;
      }
    }
    .rt-SelectItemIndicator {
      display: none;
    }
  }
  //error
  .flag-error {
    .rt-SelectTrigger {
      border-color: var(--color-error);
    }
  }
}
