.survey {
    h3 {
        margin-bottom: var(--space-15);
    }
    .block-wrap {
        margin-top: var(--space-12);
    }
    .map-wrap {
        position: relative;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 700px;
        height: 1054px;
        background-color: var(--bg-light);
        .all {
            position: absolute;
            top: var(--space-11);
            right: var(--space-11);
            padding-right: calc(var(--space-8) - 2px);
            font-size: var(--font-size-4);
            font-weight: var(--font-weight-bold);
            line-height: calc(var(--default-font-size) * 2);
            &:hover {
                color: var(--color-primary);
            }
            &.active {
                color: var(--font-error);
                &::after {
                    background-color: var(--color-error);
                    background-image: url('../../../assets/images/icon/icon-check-white.svg');
                }
            }
            &::after {
                content: '';
                position: absolute;
                top: 50%;
                right: 0;
                width: 26px;
                height: 26px;
                border-radius: 50%;
                background-color: var(--border-darken);
                background-image: url('../../../assets/images/icon/icon-plus.svg');
                background-position: center;
                background-repeat: no-repeat;
                background-size: 16px 15px;
                transform: translateY(-50%);
            }
        }
    }
    .text-wrap {
        flex-grow: 1;
        // padding: var(--space-6) var(--space-12);
        padding-top: var(--space-6);
        padding-bottom: var(--space-6);
        padding-left: var(--space-12);
    }
    h4 {
        font-size: var(--font-size-7);
        line-height: var(--line-height-8);
    }
    .description {
        color: var(--font-basic);
        font-size: var(--font-size-2);
        font-weight: var(--font-weight-medium);
        line-height: calc(var(--default-font-size) * 1.375); // 22px
    }
    .card-chart {
        margin-top: var(--space-6);
        &.type-building {
            .card-header {
                background-color: var(--color-third);
            }
            .result {
                color: var(--color-third);
            }
        }
        &.type-money {
            .card-header {
                background-color: var(--color-primary);
            }
            .result {
                color: var(--color-primary);
            }
        }
        &.type-person {
            .card-header {
                background-color: var(--color-info);
            }
            .result {
                color: var(--color-info);
            }
        }
    }
    .card-header {
        display: flex;
        align-items: center;
        padding: 0 var(--space-6);
        height: 59px;
        background-color: var(--color-primary);
        p {
            color: var(--font-white);
        }
    }
    .amount {
        display: inline-flex;
        align-items: center;
        gap: var(--space-5);
        font-size: var(--font-size-4);
        font-weight: var(--font-weight-bold);
        strong {
            font-size: var(--font-size-7);
            line-height: var(--line-height-8);
        }
    }
    .unit {
        margin-left: auto;
    }
    .card-body {
        padding: var(--space-6);
        border: 1px solid var(--border-light);
    }
    .chart-wrap {
        display: flex;
        align-items: center;
        gap: var(--space-3);
        position: relative;
        min-height: 60px;
        &:nth-of-type(n + 2) {
            margin-top: var(--space-2);
        }
        .chart {
            // display: flex;
            // flex-wrap: wrap;
            // align-items: center;
            // gap: var(--space-3);
            font-size: 0;
        }
        .result {
            font-size: var(--font-size-6);
            font-weight: var(--font-weight-bold);
            line-height: var(--line-height-7);
        }
    }
    .building-chart,
    .money-chart,
    .person-chart {
        display: flex;
        // flex-wrap: wrap;
        align-items: center;
        gap: var(--space-1) var(--space-3);
        width: 100%;
    }
    .building-chart {
        .cover {
            height: 49px;
            background-image: url('../../../assets/images/icon/icon-building-chart.svg');
            background-position: top left;
            background-repeat: repeat;
            background-size: auto 49px;
        }
    }
    .money-chart {
        .cover {
            height: 40px;
            background-image: url('../../../assets/images/icon/icon-money-chart.svg');
            background-position: top left;
            background-repeat: repeat;
            background-size: auto 40px;
        }
    }
    .person-chart {
        .cover {
            height: 42px;
            background-image: url('../../../assets/images/icon/icon-person-chart.svg');
            background-position: top left;
            background-repeat: repeat;
            background-size: auto 42px;
        }
    }
    .chart-label {
        min-width: 89px;
        color: var(--font-basic);
        font-weight: var(--font-weight-bold);
        line-height: var(--line-height-6);
    }
}

html[data-device='mobile'] {
    .survey {
        h3 {
            margin-bottom: var(--space-9);
        }
        .block-wrap {
            display: block;
            margin-top: var(--space-6);
        }
        .map-wrap {
            width: 100%;
            height: auto;
            background-color: var(--bg-white);
        }
        .text-wrap {
            padding: 0;
            margin-top: var(--space-12);
        }
        h4 {
            font-size: var(--font-size-6);
            line-height: var(--line-height-7);
        }
        .card-chart {
            margin-top: var(--space-4);
            &.type-building {
                .chart-label {
                    margin-top: 5px;
                }
            }
            &.type-person {
                .chart-label {
                    margin-top: 5px;
                }
            }
        }
        .card-header {
            padding: 0 var(--space-3);
            height: 52px;
        }
        .card-body {
            padding: var(--space-5) var(--space-3);
        }
        .amount {
            gap: var(--space-1);
            font-size: var(--font-size-3);
            strong {
                font-size: var(--font-size-5);
                line-height: calc(var(--default-font-size) * 2);
            }
        }
        .unit {
            font-size: var(--font-size-2);
        }
        .chart-wrap {
            align-items: normal;
            min-height: auto;
            &:nth-of-type(n + 2) {
                margin-top: var(--space-2);
            }
            .result {
                font-size: var(--font-size-4);
                line-height: calc(var(--default-font-size) * 2);
            }
        }
        .building-chart,
        .money-chart,
        .person-chart {
            flex-wrap: wrap;
        }
        .building-chart {
            .cover {
                height: 36px;
                background-size: auto 36px;
            }
        }
        .money-chart {
            .cover {
                height: 35px;
                background-size: auto 35px;
            }
        }
        .person-chart {
            .cover {
                height: 35px;
                background-size: auto 35px;
            }
        }

        .chart-label {
            min-width: 64px;
            font-size: var(--font-size-2);
            line-height: calc(var(--default-font-size) * 1.375); // 22px
        }
    }
}
