.taps {
  .box {
    .tap {
      color: var(--font-white);
      text-align: center;
      padding: var(--space-2);

      max-width: 438px;
      border-radius: var(--space-4) var(--space-4) 0 0;
      background: var(--color-third);

      &.info {
        background: var(--color-info);
      }
      &.primary {
        background: var(--color-primary);
      }
      &.blue {
        background: var(--bg-lightness-blue);
      }
    }
    .rectangular {
      display: flex;
      align-items: center;
      gap: var(--space-6);
      padding: var(--space-6) var(--space-9);
      border: 1px solid var(--border-light);

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 110px;
      }

      .desc-wrap {
        display: flex;
        flex-direction: column;
        gap: var(--space-4);
        color: var(--font-basic);
      }

      .role {
        color: var(--font-darken);
        display: inline-flex;
        justify-content: space-between;
        word-break: keep-all;
        width: 66px;

        p {
          display: inline-block;
          flex: 1;

          &:last-child {
            text-align: right;
          }
        }
      }

      .square {
        display: flex;
        padding: 2px var(--space-4);
        justify-content: center;
        align-items: center;
        gap: var(--space-2);
        color: var(--font-dark);
        border-radius: var(--space-4);
        background: var(--bg-light);

        white-space: nowrap;

        margin-right: var(--space-2);
      }

      .department_img {
        padding: var(--space-6) var(--space-6) var(--space-7) var(--space-4);
      }
    }
  }
}

html[data-device="mobile"] {
  .taps {
    .box {
      .rectangular {
        flex-direction: column;
        align-items: center;
        gap: var(--space-3);
        padding-top: 0;
        padding-inline: var(--space-4);
        padding-bottom: var(--space-6);
        .desc-wrap {
          width: 100%;
        }
        .icon {
          height: 130px;
        }
      }
    }
  }
}
