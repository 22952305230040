.board-search-box {
    .rt-TextFieldRoot {
        width: 270px;
        flex: unset !important;
    }
}
html[data-device='mobile'] {
    .board-search-box {
        .rt-TextFieldRoot {
            width: 100%;
            flex: unset !important;
        }
    }
}
