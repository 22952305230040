.radix-themes {
    .rt-TextFieldRoot {
        flex: 1 1;
        --text-field-focus-color: var(--color-primary);
        box-shadow: none;
        border: 1px solid var(--border-light);
        background: var(--bg-white);
        &.rt-r-size-2 {
            --text-field-height: var(--space-12);
        }
        &.rt-r-size-3 {
            --text-field-height: var(--space-17);
        }

        .rt-TextFieldInput {
            padding: calc(var(--space-2) + 1px) var(--space-3) var(--space-2) var(--space-1);
            &::placeholder {
                color: var(--gray-6);
                font-size: 15px;
            }
        }

        .rt-TextFieldSlot {
            padding: 0;
            .rt-IconButton {
                font-size: 0;
            }
        }
    }
}
[data-device='mobile'] {
    .radix-themes {
        .rt-TextFieldInput {
            padding: calc(var(--space-5) - 1px) var(--space-3);
        }
    }
}
