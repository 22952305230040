.solution-intro-wrap {
    .goal-item {
        position: relative;
        max-width: 716px;
        min-height: 120px;
        border: 5px solid var(--border-light);
        padding: var(--space-10) var(--space-6);
        margin: 0 auto var(--space-12);
        p {
            max-width: 330px;
            word-break: keep-all;
            margin: 0 auto;
        }
        &::after {
            content: '';
            display: block;
            width: 32px;
            height: 28px;
            background: url('../../../assets/images/pages/solution/icon-arrow-down.svg') no-repeat center / contain;
            position: absolute;
            bottom: -43px;
            left: 50%;
            transform: translateX(-50%);
        }

        &.gps {
            background: url('../../../assets/images/icon/icon-gps.svg') no-repeat left 30px top 22px / 68px 68px;
        }
        &.issue {
            background: url('../../../assets/images/icon/icon-stack.svg') no-repeat left 30px top 22px / 68px 68px;
            &::after {
                filter: invert(52%) sepia(80%) saturate(357%) hue-rotate(168deg) brightness(108%) contrast(87%);
            }
        }
        &.resolve {
            background: url('../../../assets/images/icon/icon-dashboard.svg') no-repeat left 30px top 22px / 68px 68px;
            &::after {
                filter: invert(54%) sepia(73%) saturate(1470%) hue-rotate(182deg) brightness(94%) contrast(97%);
            }
        }
        &.data {
            background: url('../../../assets/images/icon/icon-atom.svg') no-repeat left 30px top 22px / 68px 68px;
            &::after {
                filter: invert(48%) sepia(94%) saturate(3817%) hue-rotate(185deg) brightness(99%) contrast(87%);
            }
        }
        &.crowd {
            background: url('../../../assets/images/icon/icon-network.svg') no-repeat left 30px top 22px / 68px 68px;
            &::after {
                filter: invert(48%) sepia(76%) saturate(5192%) hue-rotate(190deg) brightness(94%) contrast(88%);
            }
        }
        &.goal {
            min-height: 188px;
            margin-bottom: 0;
            border-color: var(--color-info);
            background: url('../../../assets/images/pages/solution/icon-intro-bulb.svg') no-repeat left 42px top 27px / 76px 125px;
            &::after {
                display: none;
            }
        }
    }
}
html[data-device='mobile'] {
    .solution-intro-wrap {
        .goal-item {
            max-width: unset;
            width: 100%;
            min-height: 164px;
            padding: var(--space-25) var(--space-4) var(--space-6);
            p {
                max-width: unset;
            }

            &.gps,
            &.issue,
            &.resolve,
            &.data,
            &.crowd,
            &.goal {
                background-position: center top 20px;
            }
            &.goal {
                min-height: 285px;
                padding-top: 160px;
            }
        }
    }
}
