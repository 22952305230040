@font-face {
  font-family: 'NotoSansKR';
  src:
    url('fonts/NotoSansKR-Black.woff') format('woff'),
    url('fonts/NotoSansKR-Black.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NotoSansKR';
  src:
    url('fonts/NotoSansKR-ExtraBold.woff') format('woff'),
    url('fonts/NotoSansKR-ExtraBold.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NotoSansKR';
  src:
    url('fonts/NotoSansKR-Bold.woff') format('woff'),
    url('fonts/NotoSansKR-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NotoSansKR';
  src:
    url('fonts/NotoSansKR-SemiBold.woff') format('woff'),
    url('fonts/NotoSansKR-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NotoSansKR';
  src:
    url('fonts/NotoSansKR-Medium.woff') format('woff'),
    url('fonts/NotoSansKR-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NotoSansKR';
  src:
    url('fonts/NotoSansKR-Regular.woff') format('woff'),
    url('fonts/NotoSansKR-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NotoSansKR';
  src:
    url('fonts/NotoSansKR-Light.woff') format('woff'),
    url('fonts/NotoSansKR-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

// 어그로체 - 인포그래픽용
@font-face {
  font-family: 'SBAggroB';
  src: url('https://cdn.jsdelivr.net/gh/fontbee/font@main/SANDBOX/SBAggroB.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'SBAggroM';
  src: url('https://cdn.jsdelivr.net/gh/fontbee/font@main/SANDBOX/SBAggroM.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'SBAggroL';
  src: url('https://cdn.jsdelivr.net/gh/fontbee/font@main/SANDBOX/SBAggroL.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}
