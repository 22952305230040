.radix-themes {
    .rt-BaseButton {
        font-size: var(--default-font-size);
        line-height: var(--line-height-6); // 30px
        font-weight: var(--font-weight-bold);
        &.rt-r-size-1 {
            --base-button-height: var(--space-8);
        }
        &.rt-r-size-2 {
            --base-button-height: var(--space-12);
        }
        &.rt-r-size-3 {
            --base-button-height: var(--space-17);
        }
        &.rt-r-size-lg {
            --base-button-height: var(--space-18);
        }
        &.btn-lg {
            min-width: 247px;
        }
        &.rt-variant-text {
            box-sizing: border-box;
            height: var(--base-button-height);
            background-color: transparent;
            color: var(--font-basic);
            svg {
                color: inherit;
            }
            &:hover {
                color: var(--accent-12);
            }
        }
        &.rt-variant-outline {
            box-shadow: var(--border-light);
            border: 1px solid var(--border-light);
            &.outline-dark {
                border-color: var(--border-darken);
            }
        }
        &.rt-variant-ghost {
            &:hover,
            &:focus,
            &:active {
                background: transparent;
            }
        }
        &.fluid {
            width: 100%;
        }
    }
    .rt-Button {
        &.rt-r-size-2 {
            padding-left: var(--space-10);
            padding-right: var(--space-10);
            &:has(svg) {
                gap: var(--space-8);
                padding-left: var(--space-6);
                padding-right: var(--space-4);
            }
        }
    }
}
