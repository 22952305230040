.radix-themes {
    .rt-BaseDialogContent {
        width: 100%;
        max-width: 482px;
        padding: var(--space-9) var(--space-8);

        .rt-Heading {
            margin-bottom: var(--space-7);
        }

        .fluid {
            text-align: center;
            word-break: keep-all;
            .rt-variant-solid {
                background: var(--bg-darkness);
                color: var(--font-white);
            }
            .rt-variant-outline {
                border-color: var(--bg-darken);
                color: var(--font-color-basic);
                &:hover {
                    background: transparent;
                }
            }
        }
    }
}
