.table-list-type {
    border-block-start: 1.5px solid var(--border-darken);
    > div {
        border-block-end: 1px solid var(--border-light);
    }
    .table-tag {
        height: var(--space-8);
        border: 1px solid var(--color-primary);
        border-radius: var(--space-4);
        padding-inline: var(--space-4);
    }
}
